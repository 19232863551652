import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class DropletsAlertsService {
  baseURL = environment.baseURL;

  constructor(private http: HttpClient) { }

  deleteDropletEmailAlert (dropletID): Observable<any> {
    return this.http.delete(this.baseURL + '/droplet-alert/emailAlert/' + dropletID );
  }

  addDropletEmailAlert (dropletID): Observable<any> {
    return this.http.post(this.baseURL + '/droplet-alert/emailAlert',
      {dropletID: dropletID})
  }

  getAllDropletEmailAlerts (): Observable<any> {
    return this.http.get(this.baseURL + '/droplet-alert/emailAlert');
  }

  getAllPagerDutyAlerts (): Observable<any> {
    return this.http.get(this.baseURL + '/droplet-alert/pagerDuty');
  }

  addDropletPagerDutyAlert (dropletID): Observable<any> {
    return this.http.post(this.baseURL + '/droplet-alert/pagerDuty',
      {dropletID: dropletID});
  }

  deleteDropletPagerDutyAlert (dropletID): Observable<any> {
    return this.http.delete(this.baseURL + '/droplet-alert/pagerDuty/' + dropletID );
  }
}
