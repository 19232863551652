import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {HealthCheckSettings} from '../models/HealthcheckSettings.model';

@Injectable()
export class DataService {
    healthChecks: any;
    baseURL = environment.baseURL;
    changeEnvironment: BehaviorSubject <string> = new BehaviorSubject<string>('Production');
    healthCheck: Subject<boolean> = new Subject ();
    incomingHealthCheck: Subject<boolean> = new Subject();
    lastSync: Subject<Date> = new Subject<Date>();
    refreshMachines: Subject<any> = new Subject<any>();
    dataSubject: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) { }
    login(email: string, password: string) {
        return new Promise((resolve, reject) => {
            const postData = {
                'email': email,
                'password': password
            };
            this.http.post(`${this.baseURL}/auth/login`, postData).subscribe((data: any) => {
                localStorage.setItem('spareDashboardJWT', data.token);
                localStorage.setItem('spareName', data.user.firstName);
                resolve(data);
            },
                (err) => {
                    reject(err);
                });
        });
    }

    signup(email: string, password: string, firstName: string, lastName: string) {
        return new Promise((resolve, reject) => {
            const postData = {
                'email': email,
                'password': password,
                'firstName': firstName,
                'lastName': lastName
            };
            this.http.post(`${this.baseURL}/auth/register`, postData).subscribe((data: any) => {
                resolve(data);
            },
                (err) => {
                    reject(err);
                });
        });
    }

    parseBalancers (balancers) {
      let balancersObject = {production: [], staging: []};
      if (balancers) {
        balancersObject.production = balancers.filter(balancer => balancer.tag === 'Production');
        balancersObject.staging = balancers.filter(balancer => balancer.tag === 'Staging');
      }
      return balancersObject;
    }

    parseDroplets (droplets) {
      let dropletsObject = {production: [], staging: []};
      if (droplets) {
        dropletsObject.production = droplets.filter(droplet => droplet.tag === 'Production');
        dropletsObject.staging = droplets.filter(droplet => droplet.tag === 'Staging');
      }
      return dropletsObject;
    }

    saveDropletSettings (healthCheckSettings: HealthCheckSettings) : Observable<any> {
      return this.http.post(`${this.baseURL}/health-checks-settings`, healthCheckSettings);
    }



  saveDropletHealthCheckSettings (settings): Observable<any> {
    return this.http.post(`${this.baseURL}/health-checks-settings`, settings);
  }

}
