import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {DataService} from './services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SocketService} from './services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  selectedPage: string = 'droplets';
  selectedEnvironment: string = 'Production';
  signupSelected: boolean = false;

  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  dropDownOpened: boolean = false;
  loading: boolean = false;

  username: string;
  healthchecking: boolean = false;
  lastSyncronized: Date = new Date();

  menuPressed: boolean = false;


  constructor (private router: Router,
               private changeDetector: ChangeDetectorRef,
               private snackBar: MatSnackBar,
               private socketService: SocketService,
               private dataService: DataService) {
    this.username = localStorage.getItem('spareName');
    this.dataService.incomingHealthCheck.subscribe(bool => {
      this.healthchecking = bool;
    });
    this.socketService.unauthorized.subscribe(() => {
      this.logout();
    });
    this.dataService.lastSync.subscribe((date) => {
      this.lastSyncronized = date;
    });
  }

  async changeView (page: string ) {
    await this.router.navigate([page]);
    this.selectedPage = page;
  }
  changeEnv (env: string ) {
    this.dataService.changeEnvironment.next(env);
    this.selectedEnvironment = this.dataService.changeEnvironment.getValue();
  }

  clickedAway(event) {
    if (event.target.id === 'modalBody') {
      this.closeModal();
    }
  }


  closeModal() {
    this.email = null;
    this.firstName = null;
    this.lastName = null;
    this.password = null;
    this.confirmPassword = null;
    this.signupSelected = false;
    this.dropDownOpened = false;
  }

  cannotSubmit() {
    return this.email == null || !this.email.includes('@') || !this.email.includes('.') || this.firstName == null || this.firstName === '' || this.lastName == null || this.lastName === '' || this.password == null || this.password === '' || this.confirmPassword == null || this.confirmPassword === '' || this.password != this.confirmPassword;
  }

  signup() {
    if (!this.cannotSubmit()) {
      this.loading = true;
      this.dataService.signup(this.email, this.password, this.firstName, this.lastName).then((res: any) => {
        this.loading = false;
        this.closeModal();
        this.snackBar.open('User Added', undefined, { duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: "toastSuccess" });
        this.changeDetector.detectChanges();
      }, (err) => {
        this.loading = false;
        this.snackBar.open(err.error.err, undefined, { duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: "toast" });
        this.changeDetector.detectChanges();
      });
    }
  }

  healthCheck () {
    this.dataService.healthCheck.next(true);
    this.healthchecking = true;
  }


  logout() {
    localStorage.removeItem('spareDashboardJWT');
    localStorage.removeItem('spareName');
    this.router.navigate(['login']);
    this.socketService.logout();
  }

  shouldDisplayHeaderAndFooter () {
    return !!localStorage.getItem('spareDashboardJWT');
  }

  refreshMachines() {
    this.dataService.refreshMachines.next();
    this.dropDownOpened = false;
  }

}
