<div class="page-content" >
  <div class="filter-row">
    <spare-dropdown *ngIf="orgOptions?.length > 1"
                    [config]="{type: 'options', dropDownLabel: 'All Organizations'}"
                    (selectChanged)="getMetricsByOrgID($event)"
                    [options]="orgOptions">

    </spare-dropdown>
    <div class="dropdown" (click)="toggleDropDown(true)">
      <div  class="nameAndArrow">
        <div class="name">{{dropDownLabel}}</div>
        <i class="material-icons">arrow_drop_down</i>
      </div>
    </div>
    <div *ngIf="filterOpened" class="dropdown-content">
      <a (click)="selectChanged('week')" [ngClass]="{'active': selectedPeriod == 'week'}">Last week
        <i class="material-icons" *ngIf="selectedPeriod == 'week'">done</i>
      </a>
      <a (click)="selectChanged('thisWeek')" [ngClass]="{'active': selectedPeriod == 'thisWeek'}">This week
        <i class="material-icons" *ngIf="selectedPeriod == 'thisWeek'" >done</i>
      </a>
      <a (click)="selectChanged('month')" [ngClass]="{'active':selectedPeriod == 'month'}">Last Month
        <i class="material-icons" *ngIf="selectedPeriod == 'month'" >done</i>
      </a>
      <a (click)="selectChanged('all')" [ngClass]="{'active':selectedPeriod == 'all'}">All Time
        <i class="material-icons" *ngIf="selectedPeriod == 'all'" >done</i>
      </a>
      <div class="custom-sec">
        <p class="custom-p" [ngClass]="{'active':selectedPeriod == 'custom'}">
          Custom
          <i class="material-icons" *ngIf="selectedPeriod == 'custom'" >done</i>
        </p>
        <div class="dates-sec">
            <input   style="display: none;" (dateChange)="changeStartDate($event)" [matDatepicker]="picker" >
            <div  (click)="picker.open()" class="datepicker" > {{ selectedPeriod == 'custom'? (pickerValue.startDate| date : 'MM/dd/yyyy')    : 'From' }}                   <i class="material-icons">arrow_drop_down</i>
            </div>
            <mat-datepicker style="display: none" touchUi #picker></mat-datepicker>
            <input   style="display: none"  (dateChange)="changeEndDate($event)" [matDatepicker]="picker1" placeholder="To" >
            <div   (click)="picker1.open()" class="datepicker"> {{ selectedPeriod == 'custom'? (pickerValue.endDate| date : 'MM/dd/yyyy')   : 'To' }}
              <i class="material-icons">arrow_drop_down</i>
            </div>
            <mat-datepicker style="display: none" touchUi #picker1></mat-datepicker>
        </div>
        <div class="apply-btn"  *ngIf="selectedPeriod == 'custom'"
             [ngClass]="{'dis-btn': dateRange.startDate.isSameOrAfter(dateRange.endDate) || noOfDateChanged < 2 }" (click)="getMetricsData(environment)">Apply</div>
      </div>
    </div>
  </div>
  <div class="content centerLoader"  *ngIf="!dataLoaded" (click)="toggleDropDown(false)">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div *ngIf="dataLoaded" class="data-content" (click)="toggleDropDown(false)">

    <div class="chartContainer"  *ngFor="let chart of allMetrics">
      <div class="chart-title">{{chart.chartName}}</div>
      <canvas  baseChart
               [datasets]="chart.linesData"
               [labels]="lineChartLabels"
               [options]="lineChartOptions"
               [colors]="lineChartColors"
               [legend]="lineChartLegend"
               [chartType]="lineChartType"
               [plugins]="lineChartPlugins">
      </canvas>
    </div>
  </div>
</div>

