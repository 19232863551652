<div class="headerRowGroup">
  <div class="headerRow">
    <div class="iconAndText">
      <img src="../../../assets/LoadBalancing.svg" alt="">
      <p>{{ balancer?.loadBalancerName }}</p>
    </div>
    <div class="iconAndText" *ngIf="balancer?.droplets">
            <span class="icon-online" [ngClass]="{good: getOnlineImage(balancer).includes('good'),
              semi: getOnlineImage(balancer).includes('semi'),
              bad: getOnlineImage(balancer).includes('red')}">
            </span>
      <p [ngClass]="{good: getOnlineImage(balancer).includes('good'),
              semi: getOnlineImage(balancer).includes('semi'),
              bad: getOnlineImage(balancer).includes('red')}">
        {{getHealthyCount(balancer) }}/{{ balancer.droplets.length }}
      </p>
    </div>
  </div>
  <div class="secondRow row">
    <div class="dropletNameColumn">DROPLET NAME</div>
    <div class="infoColumn">TOTAL MEM.</div>
    <div class="infoColumn">USED MEM.</div>
    <div class="infoColumn">CPU</div>
    <div class="infoColumn">HEALTH</div>
    <div class="infoColumn">LAST UPDATE</div>
  </div>
</div>
