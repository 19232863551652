<!-- The part of the droplets -->
<div class="content content-desktop" *ngIf="healthChecks && col1Balancers && col2Balancers">
  <div class="column">
    <div class="card" *ngFor="let balancer of col1Balancers; trackBy:balancer?.loadBalancerID">

      <spare-droplets-card-header [balancer]="balancer"
                                  [healthChecks]="healthChecks">
      </spare-droplets-card-header>

      <spare-droplet-item [ngClass]="{evenRow: i%2 === 1, lastRow: i+1 === balancer.droplets.length}"
                          *ngFor="let droplet of balancer.droplets; trackBy:droplet?.dropletID; let i = index;"
                          [healthChecks]="healthChecks"
                          (openSettingsModal)="openSettingsModal($event.droplet, true)"
                          [droplet]="droplet">
      </spare-droplet-item>
    </div>
    <!--    Single droplet -->
    <spare-droplet-item [ngClass]="{evenRow: i%2 === 1}"
                        *ngFor="let droplet of col1Droplets; trackBy:droplet?.dropletID; let i = index;"
                        [singleDroplet]="true"
                        [healthChecks]="healthChecks"
                        (openSettingsModal)="openSettingsModal($event.droplet, false)"
                        [droplet]="droplet">
    </spare-droplet-item>
    <div class="padding"></div>
  </div>
  <div class="column">
    <div class="card" *ngFor="let balancer of col2Balancers; trackBy:balancer?.loadBalancerID">

      <spare-droplets-card-header [balancer]="balancer" [healthChecks]="healthChecks"></spare-droplets-card-header>

      <spare-droplet-item [ngClass]="{evenRow: i%2 === 1, lastRow: i+1 === balancer.droplets.length}"
                          *ngFor="let droplet of balancer.droplets; trackBy:droplet?.dropletID; let i = index;"
                          [healthChecks]="healthChecks"
                          (openSettingsModal)="openSettingsModal($event.droplet, true)"
                          [droplet]="droplet">
      </spare-droplet-item>
    </div>
    <!--    Single droplet -->
    <spare-droplet-item [ngClass]="{evenRow: i%2 === 1}"
                        *ngFor="let droplet of col2Droplets; trackBy:droplet?.dropletID; let i = index;"
                        [singleDroplet]="true"
                        [healthChecks]="healthChecks"
                        (openSettingsModal)="openSettingsModal($event.droplet, false)"
                        [droplet]="droplet">
    </spare-droplet-item>
    <div class="padding"></div>
  </div>
</div>
<div class="content centerLoader" *ngIf="!healthChecks">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<spare-droplet-settings-modal
  (modalClosed)="modalClosed($event)"
  [emailAlertChecked]="isEmailAlertChecked()"
  [pagerDutyAlertChecked]="isPagerDutyAlertChecked()"
  *ngIf="selectedDroplet" [droplet]="selectedDroplet" >

</spare-droplet-settings-modal>
