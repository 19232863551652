import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChartData} from '../../models/chart-data.model';
import {Label} from 'ng2-charts';
import {DatePipe} from '@angular/common';
import {DropdownOption} from '../../models/dropdown.option.model';

@Injectable()
export class MetricsService {
  baseURL = environment.baseURL;
  datePipe = new  DatePipe('en-US');
  constructor(private http: HttpClient) {}

  getAllMetricsByEnvironment (dateRange, env: string, orgID: number): Observable<any> {
     if (env === 'Production') env = 'prod';
     let params: any= {
       startDate: dateRange.startDate.unix() *1000,
       endDate: dateRange.endDate.unix() *1000,
     };
     if (orgID) params.orgID = orgID;
     return this.http
       .get(`${this.baseURL}/metrics/${env.toLowerCase()}`,{params})
       .pipe(map((res: any) =>{
         return this.structureMetricsToChart(res);
       }));
  }

  getAllStagingMetricsByOrgID (dateRange, orgID: number): Observable<any> {
    return this.http.get(`${this.baseURL}/metrics/staging/${orgID}?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`)
      .pipe(map((res: any) =>{
        return this.structureMetricsToChart(res);
      }));
  }

  getAllProductionMetricsByOrgID (dateRange, orgID: number): Observable<any> {
    return this.http.get(`${this.baseURL}/metrics/production/${orgID}?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`)
      .pipe(map((res: any) =>{
        return this.structureMetricsToChart(res);
      }));
  }

  getAllOrganizationByEnv(env: string): Observable<DropdownOption[]> {
    return this.http.get(`${this.baseURL}/metrics/organizations/${env.toLowerCase()}`).pipe(map((orgs:any) => {
      let options: DropdownOption[]=[];
      orgs.forEach (item =>{
        options.push({name: item.organizationName, value: item.organizationID});
      });
      return options;
    }));
  }

  structureMetricsToChart (data: any[]) {
    let chartsData: ChartData[]= [];
    let xLabel: Label[] =[];
    data.forEach(item => {
      // Iterate on the Array that comes from the Back-end
      for (let attr in item) {
        if (attr !== 'date' && !attr.startsWith('_') && attr !=='organizationID' && attr !== 'organizationName') {
          // Check if chartsData includes chart with current name and add a new one if not.
          const chart = chartsData.find(cat => cat.chartName === this.convertCamelCase(attr));
          if (!chart) chartsData.push({chartName: this.convertCamelCase(attr), linesData: []});
          const charIndex = chartsData.findIndex((char => char.chartName == this.convertCamelCase(attr)));
          for (let lineName in item[attr][0]) {
            // Check if the chart includes the line an add a new one if not.
            if (!chartsData[charIndex].linesData
              .find(point => point.label === this.convertCamelCase(lineName))) {
              chartsData[charIndex].linesData.push({data: [item[attr][0][lineName]],
                label: this.convertCamelCase(lineName)});
            }
            else {
              // if NOT adding  the data to the existing one.
              const index =  chartsData[charIndex].linesData
                .findIndex(point => point.label === this.convertCamelCase(lineName));
              ( chartsData[charIndex].linesData[index].data as number[] ).push(item[attr][0][lineName]);
            }
          }
        }
      }
      // Fill the time in the xLabel array
      if (! xLabel.includes(this.datePipe.transform(item.date, 'short'))) {
        xLabel.push(this.datePipe.transform(item.date, 'short'));
      }
    });
    return {chartsData: chartsData, xLabel: xLabel};
  }

  private convertCamelCase (data: string) {
    let result = data.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

}
