import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorHandlerService {

  constructor(private snackBar: MatSnackBar) { }

  handleError(error) {
    console.error(error);
    if (error.error.message) {
      this.snackBar.open(error.error.message, undefined,
        {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "toast"
        });
    } else {
      this.snackBar.open('Something went wrong ', undefined,
        {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "toast"
        });
    }
  }
}
