import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SpareDroplet} from '../../models/droplet.model.model';
import * as moment from 'moment';

@Component({
  selector: 'spare-droplet-item',
  templateUrl: './droplet-item.component.html',
  styleUrls: ['./droplet-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropletItemComponent implements OnInit {

  @Input() singleDroplet = false;
  @Input() droplet: SpareDroplet;
  @Input() healthChecks;

  @Output() openSettingsModal: EventEmitter<{droplet: SpareDroplet}> =
    new EventEmitter<{droplet: SpareDroplet; digitalOceanSettings: boolean}>()

  constructor(private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    setInterval(() => this.changeDetection.detectChanges(), 1000)
  }

  /**
   * return a string that describes the health status off passed droplet
   * @param droplet {SpareDroplet} - the droplet needs to get the health status for
   * @returns {'Healthy'| 'mid'|'UnHealthy'|'NA'} - the string that describe the health status
   */
  getHealthyStatusDroplet(droplet: SpareDroplet): 'Healthy'| 'mid'|'UnHealthy'|'NA' {
    if (droplet.healthCheckSettings) {
      const healthyThreshold: number = droplet.healthCheckSettings.healthy_threshold;
      const unhealthyThreshold: number = droplet.healthCheckSettings.unhealthy_threshold;
      if (this.healthChecks[droplet.dropletID]) {
        const healthyCount: number = +this.healthChecks[droplet.dropletID].healthyCount;
        if (healthyCount === healthyThreshold) {
          return "Healthy";
        } else if (healthyCount < healthyThreshold && healthyCount > unhealthyThreshold) {
          return 'mid';
        } else if (healthyCount <= unhealthyThreshold) {
          return "UnHealthy";
        } else {
          return "UnHealthy";
        }
      }
    } else {
      return "NA";
    }
  }

  /**
   * Returns the machine total memory for passed droplet
   * @param droplet {SpareDroplet} - spare droplet that needs to ger the total memory for
   * @returns  {memory: string, statusClass: '' | 'na' }
   */
  getMachineTotalMemory(droplet: SpareDroplet): {memory: string, statusClass: '' | 'na' } {
    const dropletHealthChecks = this.healthChecks[droplet.dropletID];
    const dropletMachineInfo = this.healthChecks[droplet.dropletID]?.machineInfo;
    const dropletMachineMemory= dropletMachineInfo?.MEMORY;
    const dropletMachineTotalMemory = dropletMachineMemory?.totalMem / (1024 * 1024);

    if (droplet.healthCheckSettings && dropletHealthChecks) {
      if (this.healthChecks && dropletMachineTotalMemory) {
        const memory = dropletMachineTotalMemory.toFixed(0) + ' MB'

        return {
          memory,
          statusClass: ''
        };
      } else  return {
        memory: 'N/A',
        statusClass: 'na'
      };
    }
    return {
      statusClass: 'na',
      memory: ''
    }
  }


  /**
   * Returns the machine used memory for passed droplet
   * @param droplet {SpareDroplet} - spare droplet that needs to ger the used memory for
   * @returns  {memory: string, statusClass: 'good' | 'semi' | 'bad' | 'na' }
   */
  getMachineUsedMemory(droplet: SpareDroplet): {memory: string, statusClass: 'good' | 'semi' | 'bad' | 'na' } {
    const dropletHealthChecks = this.healthChecks[droplet.dropletID];
    const dropletMachineInfo =  dropletHealthChecks?.machineInfo;
    const dropletMemory = dropletMachineInfo?.MEMORY;
    const dropletUsedMemory = dropletMemory?.usedMem / (1024 * 1024)

    if (droplet.healthCheckSettings && dropletHealthChecks) {
      if (this.healthChecks && dropletUsedMemory) {
        const memory = (dropletUsedMemory ).toFixed(0) + ' MB';
        let statusClass;

        if (dropletUsedMemory <= 500 ) statusClass = 'good';
        else if (dropletUsedMemory < 800) statusClass = 'semi';
        else  statusClass = 'bad';

        return {
          memory,
          statusClass
        };
      } return {
        memory: 'N/A',
        statusClass: 'na'
      };
    }
    return {
      statusClass: 'na',
      memory: ''
    }
  }

  /**
   * check if last check for the passed droplet is stale or not
   * @param droplet
   * @returns {boolean}
   */
  isDropletHCDataStale(droplet: SpareDroplet): boolean {
    if (this.healthChecks[droplet.dropletID]) {
      const healthCheckIntervals = droplet.healthCheckSettings.check_interval_seconds
      const lastHCTime  = moment(this.healthChecks[droplet.dropletID].lastHealthCheckTime);
      return moment().diff(lastHCTime, 'seconds') >= healthCheckIntervals +1;
    } else return  false;
  }

  /**
   * get the CPU used power for passed droplet
   * @param droplet {SpareDroplet} - the droplet needs to get the used cpu power for
   * @returns  {percentage: string, statusClass: 'good' | 'semi' | 'bad' | 'na' }
   */
  getMachineUsedCPU(droplet: SpareDroplet): {percentage: string, statusClass: 'good' | 'semi' | 'bad' | 'na' } {
    const dropletHealthChecks = this.healthChecks[droplet.dropletID];
    const dropletMachineInfo = dropletHealthChecks?.machineInfo;
    const dropletCPUInfo = dropletMachineInfo?.CPU;
    const CPUCurrentLoad = dropletCPUInfo?.currentLoad;

    if (droplet.healthCheckSettings && dropletHealthChecks) {
      if (this.healthChecks && CPUCurrentLoad) {
        let statusClass;

        if (CPUCurrentLoad <= 50 ) statusClass = 'good';
        else if (CPUCurrentLoad < 80) statusClass = 'semi';
        else  statusClass = 'bad';

        return {
          percentage: (CPUCurrentLoad).toFixed(2) + '%',
          statusClass
        };
      } else {
        return {
          percentage: 'N/A',
          statusClass: 'na'
        };
      }
    } else return {
      percentage: '',
      statusClass: 'na'
    };
  }


  /**
   * get health percentage for passed droplet depend on health threshold and number of healthy health checks
   * @param droplet {SpareDroplet} - the droplet needs to get the health percentage for
   * @returns  {percentage: string, statusClass: 'good' | 'semi' | 'bad' | 'na' }
   */
  getHealthPercentageMinute(droplet: SpareDroplet) :  {percentage: string, statusClass: 'good' | 'semi' | 'bad' | 'na' } {
    const dropletHealthCheck = this.healthChecks[droplet.dropletID];
    const dropletHealthCount = dropletHealthCheck?.healthyCount;
    const dropletHealthyThreshold = dropletHealthCheck?.healthyThreshold;

    if (droplet.healthCheckSettings && dropletHealthCheck ) {
      if (dropletHealthCheck) {
        const percentage = ((dropletHealthCount / dropletHealthyThreshold) * 100);
        let statusClass;

        if (percentage >= 75 ) statusClass = 'good';
        else if (percentage > 50) statusClass = 'semi';
        else  statusClass = 'bad';

        return {
          percentage: percentage.toFixed(0) + '%',
          statusClass
        }
      } else return {
        percentage: '',
        statusClass: 'na'
      };
    }
    return {
      statusClass: 'na',
      percentage:'N/A'
    }
  }


  /**
   * check if the data we have for passed droplet still valid or not based on the check interval in health check settings
   * @param droplet {SpareDroplet}
   * @returns {boolean}
   */
  getDateStatus (droplet): boolean {
    if (this.healthChecks[droplet.dropletID]) {
      const healthCheckDate = moment(this.healthChecks[droplet.dropletID].lastHealthCheckTime).unix();
      const healthCheckInterval = +droplet.healthCheckSettings['check_interval_seconds'];
      const nextHCTime =  healthCheckDate + healthCheckInterval;
      if (moment(0).unix() < nextHCTime + 20 ) return true;
    }
    return false
  }


  /**
   * get the last check time from now duration
   * @param droplet {SpareDroplet}
   * @returns {string}
   */
  getLastCheckTime (droplet: SpareDroplet) : string {
    if (this.healthChecks[droplet.dropletID]) {
      const lastCheckTime = this.healthChecks[droplet.dropletID].lastHealthCheckTime;
      moment.updateLocale('en', {
        relativeTime: {
          s: '%ds',
        }
      })
      return moment(lastCheckTime).fromNow();
    }
  }


}
