<div class="backdrop" >
  <div class="modal">
    <div class="modal-header">
      <div class="title-sec">
        {{droplet.dropletName}}
      </div>
      <div class="action-bts">
        <img  src="../../../assets/Group%2069.svg" (click)="modalClosed.emit(false)" alt="">
      </div>
    </div>
    <div class="modal-body">
      <div *ngIf="!_shouldViewForm" class="pre-adding-hc">
        <img  src="assets/cactus.svg" alt="">
        <h2>A health check has not been configured for this droplet</h2>
        <div  class="config-btn" (click)="_shouldViewForm=true" >
          <i >Configure</i>
        </div>
      </div>
      <div *ngIf="_shouldViewForm" class="content">
        <form [formGroup]="_settingForm">
          <h2 class="title">Health Checks</h2>
          <div class="setting-section">
            <div class="row">
              <div class="settings-box">
                <i class="settings-box-title" >Target</i>
                <div class="spare-form-field">
                  <label for="protocol">PROTOCOL</label>
                  <spare-dropdown
                    id="protocol"
                    [config]="{type: 'options'}"
                    [options]="_protocols"
                    formControlName="protocol"></spare-dropdown>
                </div>
                <div class="spare-form-field">
                  <label for="port">PORT</label>
                  <input class="spare-form-field-input"
                         id="port"
                         type="text"
                         formControlName="port">
                </div>
                <div class="spare-form-field">
                  <label for="path">PATH</label>
                  <input class="spare-form-field-input"
                         id="path"
                         type="text"
                         formControlName="path">
                </div>
                <div class="spare-form-field">
                  <label for="host">HOST</label>
                  <input class="spare-form-field-input"
                         id="host"
                         type="text"
                         formControlName="host">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="settings-box">
                <i class="settings-box-title" >Settings</i>
                <div class="spare-form-field">
                  <label for="check-interval">CHECK INTERVAL (IN S)</label>
                  <input class="spare-form-field-input"
                         id="check-interval"
                         type="text"
                         formControlName="checkInterval">
                </div>
                <div class="spare-form-field">
                  <label for="response-timeout">RESPONSE TIMEOUT (IN S)</label>
                  <input class="spare-form-field-input"
                         id="response-timeout"
                         type="text"
                         formControlName="responseTimeout">
                </div>
                <div class="spare-form-field">
                  <label for="unhealthy-threshold">UNHEALTHY THRESHOLD </label>
                  <input class="spare-form-field-input"
                         id="unhealthy-threshold"
                         type="text"
                         formControlName="unhealthyThreshold">
                </div>
                <div class="spare-form-field">
                  <label for="healthy-threshold">HEALTHY THRESHOLD</label>
                  <input class="spare-form-field-input"
                         id="healthy-threshold"
                         type="text"
                         formControlName="healthyThreshold">
                </div>
              </div>
            </div>
          </div>
          <h2 class="title">Personal Alerts</h2>
          <div class="alert-section">
            <h5 class="alert-title">EMAIL</h5>
            <p class="alert-description">
              Select whether you want alerts for this droplet to be delivered to your personal email.
            </p>
            <mat-slide-toggle  [checked]="emailAlertChecked" (change)="_shouldSubmitEmailAlert = true;" formControlName="emailAlert">

            </mat-slide-toggle>
          </div>
          <h2 class="title">System Wide Alerts</h2>
          <div class="alert-section">
            <h5 class="alert-title">PAGERDUTY</h5>
            <p class="alert-description">
              Select whether this droplet should send alerts to our PagerDuty integration.
            </p>
            <mat-slide-toggle [checked]="pagerDutyAlertChecked" (change)="_shouldSubmitPDAlert = true;" formControlName="PDAlert">

            </mat-slide-toggle>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <div class="content centerLoader" *ngIf="_isLoading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div  (click)="submitSettings()" class="save-btn" *ngIf="!_isLoading && _shouldViewForm" >
        <i >Save</i>
      </div>
    </div>
  </div>
</div>
