import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  loading: boolean = false;

  constructor(private dataService: DataService,
              private router: Router,
              public snackBar: MatSnackBar) {

  }

  ngOnInit() {
  }

  cannotSubmit() {
    return this.email == null || !this.email.includes('@') || !this.email.includes('.') || this.password == null || this.password === '';
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.cannotSubmit()) {
        this.login();
      }
    }
  }

  login() {
    if (!this.cannotSubmit()) {
      this.loading = true;
      this.dataService.login(this.email, this.password).then((res) => {
        this.loading = false;
        this.router.navigate(['']);
      }, (err) => {
        this.loading = false;
        this.snackBar.open('Wrong Credentials', undefined, { duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: "toast" })
      });
    }
  }

}
