import {
  ChangeDetectorRef,
  Component,
  EventEmitter, forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {SpareDropDownConfig} from './models/spare-dropdown-config.model';
import * as moment from 'moment';
import {DateRange} from './models/date-range.model';
import {DropdownOption} from '../../models/dropdown.option.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'spare-dropdown',
  templateUrl: './spare-dropdown.component.html',
  styleUrls: ['./spare-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpareDropdownComponent),
      multi: true
    }
  ]
})
export class SpareDropdownComponent implements OnInit, ControlValueAccessor{
  @Input()  config: SpareDropDownConfig = null;
  @Input()  options: DropdownOption[];
  @Output() selectChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateRangeChanged: EventEmitter<DateRange> = new EventEmitter<DateRange>();

   selectedOption: string = this.config? this.config.defaultValue.value : 'all';
   _isDisabled: boolean = false;
   dateRange: DateRange = {
    startDate: moment(0).utc(),
    endDate: moment().utc()
  };
  private pickerValue: any = {
    startDate: moment(),
    endDate: moment()
  };
  private noOfDateChanged: number = 0;
  dropDownTopPosition;
  dropDownRightPosition;
  datePickerOpened: boolean = false;
  @HostBinding('attr.tabindex') tabindex = '0';
  @HostListener('blur', ['$event.target']) onBlur(target) {
    if (!this.datePickerOpened) {
      this.toggleDropDown(false);
    }
  }
  private propagateChange = (_:any) => {};

  constructor(private changeDetector: ChangeDetectorRef) {

  }
  ngOnInit() {

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  writeValue(value: any): void {
    if (this.config.type == 'options') {
      this.selectedOption= value ? value : this.config.defaultValue;
      const selectedOption: DropdownOption = this.options.find(option => option.value === value);
      this.config.dropDownLabel = selectedOption? selectedOption.name: this.config.dropDownLabel;
    }
  }
  /**
   * Toggle dropdown.
   * @param state {boolean}
   * false: to close the dropdown.
   * true: to open the dropdown.
   */
  toggleDropDown(state: boolean) {
    this.config.toggled = state;
    this.changeDetector.detectChanges();
    if (state && !this.dropDownRightPosition && !this.dropDownTopPosition) {
      const dropDowns = document.querySelector('.dropdown-content');
      this.dropDownTopPosition = getComputedStyle(dropDowns).top;
      this.dropDownRightPosition = getComputedStyle(dropDowns).right;
      console.log(this.dropDownRightPosition);
    }
  }

  /**
   * event emit of when select an option.
   * @param event: native event.
   * @param value : the value of option selected.
   */
  changeSelect (event: any,  value?: string) {
    if (this.config.type == 'dateRange' ){
      if (value === 'week') {
        this.dateRange.startDate = moment().utc().subtract(1, 'weeks');
        this.dateRange.endDate =  moment().utc();
        this.config.dropDownLabel = 'Last Week';
      } else if (value == 'month') {
        this.dateRange.startDate = moment().utc().subtract(1, 'months');
        this.dateRange.endDate =  moment().utc();
        this.config.dropDownLabel = 'Last Month';
      } else if (value === 'thisWeek') {
        if (moment().day() == 0) {
          this.dateRange.startDate = moment().utc().startOf('day');
        } else {
          this.dateRange.startDate = moment().utc().subtract(moment().day(), 'days');
        }
        this.dateRange.endDate =  moment().utc();
        this.config.dropDownLabel= 'This Week';
      } else if (value === 'all') {
        this.dateRange.startDate = moment(0);
        this.dateRange.endDate =  moment().utc(true);
        this.config.dropDownLabel = 'All Time';
        this.noOfDateChanged = 0;
      }
      this.toggleDropDown(false);
      this.pickerValue =  {
        startDate: moment().utc(),
        endDate: moment().utc()
      };
      this.selectedOption= value;
      this.dateRangeChanged.emit(this.dateRange);
    } else {
      this.selectChanged.emit(value);
      this.selectedOption = value;
      if (event.target.innerText.endsWith('done') && event.target.innerText !== 'done') {
        const innerTextArray =  event.target.innerText.split('\n');
        this.config.dropDownLabel = innerTextArray[0];
      } else if (event.target.innerText !== 'done') {
        this.config.dropDownLabel = event.target.innerText;
      }
      this.propagateChange(value);
    }
    this.toggleDropDown(false);
  }

  /**
   * emit when the start date is changed.
   * @param event: event object comes from date picker component.
   */
  changeStartDate(event) {
    this.noOfDateChanged++;
    console.log(this.noOfDateChanged)
    this.dateRange.startDate = moment(event.value).utc();
    this.pickerValue.startDate = moment(event.value).utc().toDate();
    this.selectedOption= 'custom';
    this.config.dropDownLabel = this.dateRange.startDate.format('DD/MM/YYYY').toString() +
      ' - ' +  this.dateRange.endDate.format('DD/MM/YYYY').toString()

  }

  /**
   *  emit when the end date is changed.
   * @param event: event object comes from date picker component.
   */
  changeEndDate(event) {
    this.noOfDateChanged++;
    console.log(this.noOfDateChanged)
    this.dateRange.endDate = moment(event.value).utc().add(1, 'day');
    this.pickerValue.endDate = moment(event.value).utc().toDate();
    this.selectedOption= 'custom';
    this.config.dropDownLabel = this.dateRange.startDate.format('DD/MM/YYYY').toString() +
      ' - ' +  this.dateRange.endDate.format('DD/MM/YYYY').toString()

  }

  /**
   * Open the date bicker.
   * @param picker: date picker reference.
   */
  openDatePicker (picker: any) {
    picker.open();
    this.datePickerOpened = true;
  }

  /**
   * FIre when apply button is clicked with te date range was selected from the date pickers.
   */
  apply () {
    this.dateRangeChanged.emit(this.dateRange);
    this.toggleDropDown(false);
  }

}
