
<div class="droplet"
     [ngClass]="{'single-droplet': singleDroplet, 'row': !singleDroplet}"
     >

  <div class="secondRow row" *ngIf="singleDroplet">
    <div class="dropletNameColumn">DROPLET NAME</div>
    <div class="infoColumn">TOTAL MEM.</div>
    <div class="infoColumn">USED MEM.</div>
    <div class="infoColumn">CPU</div>
    <div class="infoColumn">HEALTH</div>
    <div class="infoColumn">LAST UPDATE</div>
  </div>

  <div  [ngClass]="{'dropletCard': singleDroplet, 'rowStart': !singleDroplet, 'row': !singleDroplet}">
    <div class="rowStart dropletNameColumn droplet-name-col">
      <div class="circle"  [ngClass]="{goodCircle: getHealthyStatusDroplet(droplet) === 'Healthy',
           'badCircle': getHealthyStatusDroplet( droplet) === 'UnHealthy',
           'semiCircle': getHealthyStatusDroplet(droplet) === 'mid',
           'na-circle': getHealthyStatusDroplet(droplet) === 'NA'}">
      </div>
      <img src="../../../assets/server.svg" alt=""> <!-- need to check the type of the server -->
      <p>{{ droplet.dropletName }}</p>
    </div>

    <div [class]="'infoColumn infoStats '+ getMachineTotalMemory(droplet).statusClass">
      {{ getMachineTotalMemory(droplet).memory }}
    </div>

    <div [class]="'infoColumn infoStats '+ getMachineUsedMemory(droplet).statusClass"
         [ngClass]="{'stale-data': isDropletHCDataStale(droplet)}">
      {{ getMachineUsedMemory(droplet).memory }}
    </div>

    <div [class]="'infoColumn infoStats ' + getMachineUsedCPU(droplet)?.statusClass"
         [ngClass]="{'stale-data': isDropletHCDataStale(droplet)}">
      {{ getMachineUsedCPU(droplet)?.percentage }}
    </div>

    <div [class]="'infoColumn infoStats '+ getHealthPercentageMinute(droplet).statusClass"
         [ngClass]="{'stale-data': isDropletHCDataStale(droplet)}">
      {{  getHealthPercentageMinute(droplet).percentage }}
    </div>

    <div class="infoColumn infoStats" >
      <div class="lighting-indicator">
        <img src="assets/green-lighting.svg" *ngIf="!isDropletHCDataStale(droplet)" alt="indicator">
        <img src="assets/gray-lighting.svg" *ngIf="isDropletHCDataStale(droplet)" alt="indicator">
      </div>
      <p  [ngClass]=" {good: getDateStatus(droplet), bad: !getDateStatus(droplet),
        'stale-data': isDropletHCDataStale(droplet)} ">{{ getLastCheckTime(droplet) }}</p>
    </div>

    <img src="assets/settings-cogwheel-button.svg" class="settings-icon"
         (click)="openSettingsModal.emit({droplet: droplet})"
         alt="">
  </div>
</div>
