<div class="header" *ngIf="shouldDisplayHeaderAndFooter()" unselectable="on">
  <div class="logoAndPage">
    <img src="../../../assets/logo.svg" alt="">
    <div class="pages">
      <p [ngClass]="{selectedPage: selectedPage === 'droplets'}" (click)="changeView('droplets')">Droplets</p>
      <p [ngClass]="{selectedPage: selectedPage === 'metrics'}" (click)="changeView('metrics')">Metrics</p>
    </div>
  </div>
  <div class="toggleAndName">
    <div class="toggle">
      <p [ngClass]="{selectedOption: selectedEnvironment === 'Production'}" (click)="selectedEnvironment = 'Production'; changeEnv('Production');">Production</p>
      <p [ngClass]="{selectedOption: selectedEnvironment === 'Staging'}" (click)="selectedEnvironment = 'Staging'; changeEnv('Staging');">Staging</p>
    </div>
    <div class="separator">.</div>
    <div class="dropdown">
      <div (click)="dropDownOpened = !dropDownOpened" class="nameAndArrow">
        <p>{{ username }}</p>
        <i class="material-icons">arrow_drop_down</i>
      </div>
      <div *ngIf="dropDownOpened" class="dropdown-content">
        <a (click)="signupSelected = true">Add User</a>
        <a (click)="refreshMachines()">Reload Droplets</a>
        <a class="lastRow" (click)="logout()">Logout</a>
      </div>
    </div>
  </div>
</div>
<div class="mobileHeader" unselectable="on" [ngClass]="{menuOpened: menuPressed}">
  <div class="logoAndMenu">
    <img src="../../../assets/logo.svg" alt="">
    <div>
      <button (click)="menuPressed = !menuPressed;" class="hamburger hamburger--collapse hamburgerMenu" type="button"
              [ngClass]="{'is-active': menuPressed}">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="actions">
    <p class="name">{{ username }}</p>
    <p [ngClass]="{selectedPage: selectedPage === 'droplets'}" (click)="changeView('droplets'); menuPressed=false">DROPLETS</p>
    <p [ngClass]="{selectedPage: selectedPage === 'metrics'}" (click)="changeView('metrics'); menuPressed=false">METRICS</p>
    <p class="addUser" (click)="signupSelected = true">ADD USER</p>
  </div>
  <div class="bottomRow">
    <p class="lastRow" (click)="logout()">LOGOUT</p>
    <p (click)="refreshMachines(); menuPressed=false">RELOAD DROPLETS</p>
  </div>
</div>
<div [@fadeInOut] class="hide" *ngIf="menuPressed" (click)="menuPressed = false"></div>

<router-outlet></router-outlet>

<!--<div class="footer"  *ngIf="shouldDisplayHeaderAndFooter()">-->
<!--  <div class="footerItems">-->
<!--    <img src="../../../assets/time.svg" alt="" (click)="healthCheck()" *ngIf="!healthchecking">-->
<!--    <div class="lds-roller scale" *ngIf="healthchecking">-->
<!--      <div></div>-->
<!--      <div></div>-->
<!--      <div></div>-->
<!--      <div></div>-->
<!--      <div></div>-->
<!--      <div></div>-->
<!--      <div></div>-->
<!--      <div></div>-->
<!--    </div>-->
<!--    <p>Last synchronized - {{ lastSyncronized | date:'shortTime' }}</p>-->
<!--  </div>-->
<!--  <div class="toggle showOnMobileOnly">-->
<!--    <p [ngClass]="{selectedOption: selectedEnvironment === 'Production'}" (click)="changeEnv('Production');">P</p>-->
<!--    <p [ngClass]="{selectedOption: selectedEnvironment === 'Staging'}" (click)="changeEnv('Production');">S</p>-->
<!--  </div>-->
<!--</div>-->

<div [@fadeInOut] id='modalBody' class="loginContainer" *ngIf="signupSelected" (click)="clickedAway($event)">
  <div class="registerCard" *ngIf="!loading">
    <img src="../../../assets/logoSmall.svg" alt="">
    <h1>Add User</h1>
    <input type="text" placeholder="First Name" [(ngModel)]="firstName">
    <input type="text" placeholder="Last Name" [(ngModel)]="lastName">
    <input type="email" placeholder="E-mail" [(ngModel)]="email">
    <input type="password" placeholder="Password" [(ngModel)]="password">
    <input type="password" placeholder="Confirm Password" [(ngModel)]="confirmPassword">
    <button [ngClass]="{disabled: cannotSubmit()}" (click)="signup()">Signup</button>
  </div>
  <div class="lds-roller" *ngIf="loading">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
