import { Injectable, OnInit } from "@angular/core";
import * as io from 'socket.io-client';
import { DataService } from "./data.service";
import { Subject } from "rxjs";
import {environment} from '../../environments/environment';
import {ErrorHandlerService} from '../error-handler.service';

@Injectable()
export class SocketService implements OnInit {
  socket;
  socketHost = environment.baseURL;
  unauthorized = new Subject<any>();
  refreshFailed = new Subject<any>();
  newHealthChecks = new Subject<any>();
  newEventArrive  = new Subject <any>();
  POSStatusFailed = new Subject <any>();
  newHealthCheckForDroplet = new Subject <any>()
  constructor(private dataService: DataService,
              private errorHandler: ErrorHandlerService) {
  }

  ngOnInit() {

  }

  subscribeDropletsEvents () {
    this.socket.on('dropletsAndLoaBalancers', (msg: any) => {
      const droplets = this.dataService.parseDroplets(msg.droplets);
      const loadBalancers = this.dataService.parseBalancers(msg.loadBalancers);
      this.dataService.dataSubject.next({droplets, loadBalancers});
    });

    this.socket.on('unauthorized', (err: any) => {
      this.unauthorized.next('unauthorized');
    });

    this.socket.on('healthCheckData', (data: any) => {
      this.dataService.healthChecks = data;
      this.newHealthChecks.next(data);
    });

    this.socket.on('healthCheckDataForDroplet', (data: any) => {
      this.newHealthCheckForDroplet.next(data);
    });

    this.socket.on("refreshFailed", (msg: any) => {
      console.log('refresh failed', msg);
      this.refreshFailed.next("refresh failed");
    });
  }

  authenticate(page: 'pos' | 'droplet') {

    if (this.socket) {
      this.socket.disconnect();
    }

    this.socket = io.connect(this.socketHost, {
      secure: true, transports: ['websocket']
    });

    this.socket.emit("authentication", {
      jwt: localStorage.getItem('spareDashboardJWT'),
    });

    this.socket.on("disconnect", (msg: any) => {
      console.log(this.socket);
      console.log("socket was disconnected", msg);
      this.logout();
      if(msg !== 'io client disconnect') {
        this.authenticate(page);
      }
    });

    this.subscribeDropletsEvents();
  }

  logout() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  refreshMachines() {
    this.socket.emit('refreshMachines');
  }

  refreshHealthChecks() {
    this.socket.emit('refreshHealthChecks');
  }

  refreshPOSMachine (serial: string) {
    this.socket.emit('getPOSStatus', {machineSerial:serial})
  }

}
