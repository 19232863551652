import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { DataService } from '../../services/data.service';
import { MetricsService } from '../../services/metrics/metrics.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Color, Label } from 'ng2-charts';
import { ChartOptions } from 'chart.js';
import { ChartData } from '../../models/chart-data.model';
import * as moment from 'moment';
import {ErrorHandlerService} from '../../error-handler.service';
import {DropdownOption} from '../../models/dropdown.option.model';
import {SpareDropdownComponent} from '../../spare-components/spare-dropdown/spare-dropdown.component';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {
  allMetrics: ChartData[];
  dataLoaded: boolean = false;
  filterOpened: boolean = false;
  noOfDateChanged: number = 0;
  selectedPeriod = 'week';
  dateRange = {
    startDate: moment().utc().subtract(1, 'weeks'),
    endDate: moment().utc()
  };
  pickerValue: any =  {
    startDate: moment(),
    endDate: moment()
  };
  dropDownLabel: string = 'Last Week';
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      intersect: false,
      mode: 'x'
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          displayFormats: {
            day: 'ddd MMM D'
          },
          unit: 'day'
        },
        distribution: 'linear',
        ticks: {
          source: 'auto',
          fontColor: '#ccc',
          fontFamily: 'Titillium Web',
          fontSize: 14,
        }

      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: '#ccc',
          fontFamily: 'Titillium Web',
          fontSize: 14,
        }
      }]
    },
    elements: {
      line: {
        tension: 0,
      },
      point: {
        radius: 0,
        hitRadius: 1
      }
    },
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 0,
        bottom: 25
      }
    },
    legend: {
      labels: {
        fontColor: '#fff',
        fontFamily: 'Titillium Web',
        padding: 20,
        fontSize: 16,
        usePointStyle: true
      },
      position: 'top',
    },
    plugins: {

    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#1CB4BC',
      pointBackgroundColor: '#1cb4bc'
    },
    {
      borderColor: '#ff151f',
      pointBackgroundColor: '#ff151f'
    },
    {
      borderColor: '#00b53e',
      pointBackgroundColor: '#00b53e'
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  environment: string;
  orgOptions: DropdownOption[];
  selectedOrg;

  constructor(private metricsService: MetricsService,
              private snackBar: MatSnackBar,
              private errorHandler: ErrorHandlerService,
              private changeDetector: ChangeDetectorRef,
              private dataService: DataService) { }

  /**
   * get the current environment then get all organizations to fill the options of filters
   * and get all Metrics Data.
   */
  ngOnInit() {
    this.environment = this.dataService.changeEnvironment.getValue();
    this.dataService.changeEnvironment.subscribe(env => {
      this.environment = env;
      this.getMetricsData(env);
      this.getAllOrganization(env);
    })
  }

  /**
   * gets all production metrics from the service.
   */
  getAllMetrics(env: string, orgID?: number) {
    this.metricsService.getAllMetricsByEnvironment(this.dateRange , env, orgID).subscribe((res) => {
      this.allMetrics = res.chartsData;
      this.lineChartLabels = res.xLabel;
      console.log(this.allMetrics);
      this.dataLoaded = true;
    }, err => {
      this.errorHandler.handleError(err);
    })
  }


  /**
   * Check on the current environment and then get the metrics that related to the current environment selected
   */
  getMetricsData (env: string){
    this.dataLoaded = false;
    this.toggleDropDown(false);

    if (!this.selectedOrg) {
      this.getAllMetrics(env);
    } else {
       this.getMetricsByOrgID(this.selectedOrg)
    }
  }

  /**
   * Fire when start date changed
   * @param {Event} event - the event that returned from ng date picker
   */
  changeStartDate(event) {
    this.noOfDateChanged++;
    console.log(this.noOfDateChanged)
    this.dateRange.startDate = moment(event.value).utc();
    this.pickerValue.startDate = moment(event.value).utc().toDate();
    this.selectedPeriod= 'custom';
    this.dropDownLabel = this.dateRange.startDate.format('DD/MM/YYYY').toString() +
      ' - ' +  this.dateRange.endDate.format('DD/MM/YYYY').toString()

  }
  /**
   * Fire when end date changed
   * @param {Event} event - the event that returned from ng date picker
   */
  changeEndDate(event) {
    this.noOfDateChanged++;
    console.log(this.noOfDateChanged)
    this.dateRange.endDate = moment(event.value).utc().add(1, 'day');
    this.pickerValue.endDate = moment(event.value).utc().toDate();
    this.selectedPeriod= 'custom';
    this.dropDownLabel = this.dateRange.startDate.format('DD/MM/YYYY').toString() +
      ' - ' +  this.dateRange.endDate.format('DD/MM/YYYY').toString()

  }

  /**
   * Fire when the select of date filter changed and then set the value of the date range depend on the value
   * @param {string} value - the value that selected
   */
  selectChanged (value){
    if (value === 'week') {
      this.dateRange.startDate = moment().utc().subtract(1, 'weeks');
      this.dateRange.endDate =  moment().utc();
      this.dropDownLabel = 'Last Week';
      this.getMetricsData(this.environment);
    } else if (value == 'month') {
      this.dateRange.startDate = moment().utc().subtract(1, 'months');
      this.dateRange.endDate =  moment().utc();
      this.dropDownLabel = 'Last Month';
      this.getMetricsData(this.environment);
    } else if (value === 'thisWeek') {
      if (moment().day() == 0) {
        this.dateRange.startDate = moment().utc().startOf('day');
      } else {
        this.dateRange.startDate = moment().utc().subtract(moment().day(), 'days');
      }
      this.dateRange.endDate =  moment().utc();
      this.dropDownLabel = 'This Week';
      this.getMetricsData(this.environment);
    } else if (value === 'all') {
      this.dateRange.startDate = moment(0);
      this.dateRange.endDate =  moment().utc(true);
      this.dropDownLabel = 'All Time';
      this.getMetricsData(this.environment);
      this.noOfDateChanged = 0;
    }
    this.selectedPeriod = value;
    this.toggleDropDown(false);
    this.pickerValue =  {
      startDate: moment().utc(),
      endDate: moment().utc()
    };
  }

  /**
   * Toggle the dropdown of date filter
   * @param {boolean} state - false to close and true for open
   */
  toggleDropDown(state: boolean) {
   this.filterOpened = state;
   this.changeDetector.detectChanges();
  }

  /**
   * Get all organizations from service and set the orgOptions to fill the filter up
   * @Param {string} environment - The current environment.
   */
  getAllOrganization (environment) {
    this.orgOptions = [{value: '', name:'All Organization'}];
    this.metricsService.getAllOrganizationByEnv(environment).subscribe((res)=>{
      this.orgOptions.push(... res);
    })
  }


  /**
   * check the current selected environment and then get the org metrics depend on it.
   * @param {number} orgID - the id of the selected organization
   */
  getMetricsByOrgID (orgID: number) {
    this.toggleDropDown(false);
    this.selectedOrg = orgID;
    this.dataLoaded = false;
    if (!orgID) return this.getMetricsData(this.environment);
    this.getAllMetrics(this.environment, orgID)
  }
}
