<div class="dropdown-row" (blur)="onBlur($event.target)">
  <div class="dropdown" [ngClass]="{disabled: _isDisabled}" (click)="toggleDropDown(true)">
    <div  class="nameAndArrow">
      <div class="name">{{config.dropDownLabel}}</div>
      <i class="material-icons">arrow_drop_down</i>
    </div>
  </div>
  <div *ngIf="config.toggled && config.type === 'dateRange' && !_isDisabled"
       [style.top]="dropDownTopPosition ? 'calc('+ dropDownTopPosition + ' - 27px)' : ''"
       [style.right]="dropDownRightPosition ? 'calc('+ dropDownRightPosition + ' - -115px)' : ''"
       class="dropdown-content">
    <a (click)="changeSelect($event,'week')" [ngClass]="{'active': selectedOption == 'week'}">Last week
      <i class="material-icons" *ngIf="selectedOption == 'week'">done</i>
    </a>
    <a (click)="changeSelect($event, 'thisWeek')" [ngClass]="{'active': selectedOption == 'thisWeek'}">This week
      <i class="material-icons" *ngIf="selectedOption == 'thisWeek'" >done</i>
    </a>
    <a (click)="changeSelect($event, 'month')" [ngClass]="{'active':selectedOption == 'month'}">Last Month
      <i class="material-icons" *ngIf="selectedOption == 'month'" >done</i>
    </a>
    <a (click)="changeSelect($event, 'all')" [ngClass]="{'active':selectedOption == 'all'}">All Time
      <i class="material-icons" *ngIf="selectedOption == 'all'" >done</i>
    </a>
    <div class="custom-sec">
      <p class="custom-p" [ngClass]="{'active':selectedOption == 'custom'}">
        Custom
        <i class="material-icons" *ngIf="selectedOption == 'custom'" >done</i>
      </p>
      <div class="dates-sec">
        <input   style="display: none;" (dateChange)="changeStartDate($event)" [matDatepicker]="picker" >
        <div  (click)="openDatePicker(picker)" class="datepicker" > {{ selectedOption == 'custom'? (pickerValue.startDate| date : 'MM/dd/yyyy') : 'From' }}  <i class="material-icons">arrow_drop_down</i>
        </div>
        <mat-datepicker style="display: none" (closed)="datePickerOpened = false" touchUi #picker></mat-datepicker>
        <input   style="display: none"  (dateChange)="changeEndDate($event)" [matDatepicker]="picker1" placeholder="To" >
        <div   (click)="openDatePicker(picker1)" class="datepicker">
          {{ selectedOption == 'custom'? (pickerValue.endDate| date : 'MM/dd/yyyy')   : 'To' }}
          <i class="material-icons">arrow_drop_down</i>
        </div>
        <mat-datepicker style="display: none" (closed)="datePickerOpened = false" touchUi #picker1></mat-datepicker>
      </div>
      <div class="apply-btn"  *ngIf="selectedOption == 'custom'"
           [ngClass]="{'dis-btn': dateRange.startDate.isSameOrAfter(dateRange.endDate) || noOfDateChanged < 2 }"
           (click)="apply()">Apply</div>
    </div>
  </div>
  <div *ngIf="config.toggled && config.type === 'options' && !_isDisabled"
       [style.top]="dropDownTopPosition ? 'calc('+dropDownTopPosition+ ' - 27px)' : ''"
       [style.right]="dropDownRightPosition ? 'calc('+ dropDownRightPosition + ' - -67px)' : ''"
       class="dropdown-content">
    <a *ngFor="let option of options" (click)="changeSelect($event, option.value)"
       [ngClass]="{'active': selectedOption == option.value}">{{option.name}}
      <i class="material-icons" *ngIf="selectedOption == option.value">done</i>
    </a>
  </div>
</div>
