import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './LowerCaseUrlSerializer';
import { APP_BASE_HREF } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { DataService } from './services/data.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { SocketService } from './services/socket.service';
import { MetricsComponent } from './pages/metrics/metrics.component';
import {ChartsModule} from 'ng2-charts';
import {MetricsService} from './services/metrics/metrics.service';
import {AuthInterceptorService} from './auth-interceptor.service';
import {ErrorHandlerService} from './error-handler.service';
import { SpareDropdownComponent } from './spare-components/spare-dropdown/spare-dropdown.component';
import {AgmCoreModule} from '@agm/core';
import { CamelToSentence } from './pipes/camel-to-sentence/camel-to-sentence.pipe.pipe';
import {DropletsAlertsService} from './services/dropletAlerts/droplets-alerts.service';
import { DropletSettingsModalComponent } from './modals/droplet-settings-modal/droplet-settings-modal.component';
import {DropletsCardHeaderComponent} from './spare-components/droplets-card-header/droplets-card-header.component';
import { DropletItemComponent } from './spare-components/droplet-item/droplet-item.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MetricsComponent,
    SpareDropdownComponent,
    CamelToSentence,
    DropletSettingsModalComponent,
    DropletsCardHeaderComponent,
    DropletItemComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    ChartsModule,
    MatSelectModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAUvcZDCHJLWRgyWEUZIh9LpSlOkZuD7CI'
    })
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
    provide: UrlSerializer,
    useClass: LowerCaseUrlSerializer
    },
    DataService,
    SocketService,
    MetricsService,
    ErrorHandlerService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true,
      },
    DropletsAlertsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
