import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import {MetricsComponent} from './pages/metrics/metrics.component';
import {AuthGuard} from './auth.guard';


const routes: Routes = [
  { path: '',
    redirectTo: 'droplets',
    pathMatch: 'full' },
  {
    path: 'droplets',
    component: HomeComponent
  },
  {
    path: 'metrics',
    component: MetricsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: '**', redirectTo: 'home' },
];

const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
