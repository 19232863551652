import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor( private snackBar: MatSnackBar,
               private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = window.localStorage.getItem('spareDashboardJWT');
    // Clone the request to add the new header.
    // send the newly created request
    const authReq = req.clone({
      setHeaders: {
        'Authorization': token? token : '',
        'Access-Control-Allow-Origin': '*',
      },
    });
    return next.handle(authReq).pipe(catchError(error => {
        if (error.status === 401) {
          localStorage.clear();
          this.router.navigate(['login']);
        }
      return throwError(error);
    })) as any;
  }
}
