import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SocketService } from 'src/app/services/socket.service';
import {ErrorHandlerService} from '../../error-handler.service';
import {DropletsAlertsService} from '../../services/dropletAlerts/droplets-alerts.service';
import {SpareDroplet} from '../../models/droplet.model.model';
import * as moment from 'moment';
import {LoadBalancer} from '../../models/load-balancer.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  selectedEnvironment: string = 'Production';
  dropletsEmailAlerts = [];
  dropletsPagerDutyAlerts =[];
  color: string = "#1cb4bc";

  productionBalancers: any[] = [];
  stagingBalancers: any[] = [];
  productionDroplets: any[] = [];
  stagingDroplets: any[] = [];

  selectedBalancers: any[];
  selectedDroplets: any[];

  col1Balancers: LoadBalancer[];
  col1Droplets: SpareDroplet[];
  col2Balancers: LoadBalancer[];
  col2Droplets: SpareDroplet[];

  healthChecks: any;

  barWidth: number = 0;
  barTimeout: any;
  lastSyncronized: Date = new Date();
  selectedDroplet: SpareDroplet;
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.socketService.logout();
  }
  constructor(private router: Router,
              private dataService: DataService,
              public snackBar: MatSnackBar,
              private errorHandler: ErrorHandlerService,
              public changeDetector: ChangeDetectorRef,
              private dropletsAlertsService: DropletsAlertsService,
              private socketService: SocketService) { }

  ngOnInit() {
    if (!localStorage.getItem('spareDashboardJWT')) {
      this.router.navigate(['login']);
    } else {
      this.dataService.changeEnvironment.subscribe(env => {
        this.selectedEnvironment = env;
        this.changeEnv();
      });

      this.dataService.dataSubject.subscribe((data) => {

         // Making a deep copy of data.
          this.productionBalancers = [];
          this.stagingBalancers = [];
          this.productionDroplets = [];
          this.stagingDroplets = [];
          this.selectedBalancers = [];
          this.selectedDroplets = [];
          this.col1Balancers = [];
          this.col1Droplets = [];
          this.col2Balancers = [];
          this.col2Droplets = [];

         data.loadBalancers.production.forEach(balancer => {
           let finalBalancer = {};
           for (let key in balancer) {
             finalBalancer[key] = balancer[key];
           }
           this.productionBalancers.push(finalBalancer);
         });

        data.loadBalancers.staging.forEach(balancer => {
          let finalBalancer = {};
          for (let key in balancer) {
            finalBalancer[key] = balancer[key];
          }
          this.stagingBalancers.push(finalBalancer);
        });

        data.droplets.production.forEach(droplet => {
          let finalDroplet = {};
          for (let key in droplet) {
            finalDroplet[key] = droplet[key];
          }
          this.productionDroplets.push(finalDroplet);
        });

        data.droplets.staging.forEach(droplet => {
          let finalDroplet = {};
          for (let key in droplet) {
            finalDroplet[key] = droplet[key];
          }
          this.stagingDroplets.push(finalDroplet);
        });

        this.processData();
      });

      this.socketService.refreshFailed.subscribe(() => {
        this.snackBar.open('Failed to reload data',
          undefined, {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "toast"
        });
      });

      this.socketService.newHealthChecks.subscribe(() => {
        this.incomingHealthCheck();
      });

      this.socketService.newHealthCheckForDroplet.subscribe((d) => {
        this.incomingHealthCheckForDroplet(d)
      })
      this.dataService.healthCheck.subscribe(bool =>{
        if (bool) this.healthCheck();
      });
      this.getAllEmailAlerts();
      this.getAllPagerDutyAlerts()
    }

    this.dataService.refreshMachines.subscribe(()=>{
      this.healthChecks = null;
      this.socketService.refreshMachines();
    });

    this.socketService.authenticate('droplet');
  }

  processData() {
      this.productionBalancers.forEach((balancer, index) => {
        this.productionBalancers[index].droplets = [];
        balancer.dropletsIDs.forEach(id => {
          const dropletIndex = this.productionDroplets.findIndex(droplet => {
            return +droplet.dropletID === id
          });
          if (dropletIndex >= 0) {
            this.productionBalancers[index].droplets.push(this.productionDroplets[dropletIndex]);
            this.productionDroplets.splice(dropletIndex, 1);
          }
        });
      });
      this.stagingBalancers.forEach((balancer, index) => {
        this.stagingBalancers[index].droplets = [];
        balancer.dropletsIDs.forEach(id => {
          const dropletIndex = this.stagingDroplets.findIndex(droplet => {
            return +droplet.dropletID === id
          });
          if (dropletIndex >= 0) {
            this.stagingBalancers[index].droplets.push(this.stagingDroplets[dropletIndex]);
            this.stagingDroplets.splice(dropletIndex, 1);
          }
        });
      });
    if (this.selectedEnvironment === 'Production') {
      this.selectedBalancers = this.productionBalancers;
      this.selectedDroplets = this.productionDroplets;
    } else {
      this.selectedBalancers = this.stagingBalancers;
      this.selectedDroplets = this.stagingDroplets;
    }
    this.distribute();
    this.updateLastSyncronized();
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  }

  incomingHealthCheck() {
    this.dataService.incomingHealthCheck.next(true);
    this.healthChecks = this.dataService.healthChecks;
    this.updateLastSyncronized();
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
    this.dataService.incomingHealthCheck.next(false);
  }

  incomingHealthCheckForDroplet(hc) {
    const dropletID = Object.keys(hc)[0];
    if (!this.healthChecks) return;
    this.healthChecks[dropletID] = hc[dropletID];
    this.updateLastSyncronized();
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  }

  changeEnv() {
      if (this.selectedEnvironment === 'Production') {
        this.selectedBalancers = this.productionBalancers;
        this.selectedDroplets = this.productionDroplets;
      } else {
        this.selectedBalancers = this.stagingBalancers;
        this.selectedDroplets = this.stagingDroplets;
      }
      this.distribute();
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  }

  distribute() {
    let tempCol1Balancers = [];
    let tempCol2Balancers = [];
    let tempCol1Droplets = [];
    let tempCol2Droplets = [];
    let col1Size = 0;
    let col2Size = 0;
    if (this.selectedBalancers) {
      for (let balancer of this.selectedBalancers) {
        if (col2Size < col1Size) {
          tempCol2Balancers.push(balancer);
          col2Size += balancer.droplets.length + 1;
        }
        else {
          tempCol1Balancers.push(balancer);
          col1Size += balancer.droplets.length + 1;
        }
      }
    }
    if (this.selectedDroplets) {
      for (let droplet of this.selectedDroplets) {
        if (col2Size < col1Size) {
          tempCol2Droplets.push(droplet);
          col2Size += 1;
        }
        else {
          tempCol1Droplets.push(droplet);
          col1Size += 1;
        }
      }
    }
    if (col1Size < col2Size) {
      this.col1Balancers = tempCol2Balancers;
      this.col1Droplets = tempCol2Droplets;
      this.col2Balancers = tempCol1Balancers;
      this.col2Droplets = tempCol1Droplets;
    }
    else {
      this.col2Balancers = tempCol2Balancers;
      this.col2Droplets = tempCol2Droplets;
      this.col1Balancers = tempCol1Balancers;
      this.col1Droplets = tempCol1Droplets;
    }

  }

  healthCheck() {
    this.dataService.incomingHealthCheck.next(true);
    this.socketService.refreshHealthChecks();
  }

  getAllEmailAlerts () {
     this.dropletsAlertsService.getAllDropletEmailAlerts().subscribe(res => {
       this.dropletsEmailAlerts = res;
       this.changeDetector.detectChanges();
     }, err => {
       this.errorHandler.handleError(err);
     })
  }

  getAllPagerDutyAlerts () {
    this.dropletsAlertsService.getAllPagerDutyAlerts().subscribe(res => {
      this.dropletsPagerDutyAlerts = res;
      this.changeDetector.detectChanges();
    }, err => {
      this.errorHandler.handleError(err);
    })
  }

  getDateStatus (droplet): boolean {
    if (this.healthChecks[droplet.dropletID]) {
      const healthCheckDate = moment(this.healthChecks[droplet.dropletID].lastHealthCheckTime).unix();
      const healthCheckInterval = +droplet.healthCheckSettings['check_interval_seconds'];
      const nextHCTime =  healthCheckDate + healthCheckInterval;
      if (moment(0).unix() < nextHCTime + 20 ) return true;
    }
    return false
  }

  isEmailAlertChecked (): boolean {
    return !!this.dropletsEmailAlerts.find((alert) => alert.dropletID == this.selectedDroplet.dropletID);
  }

  isPagerDutyAlertChecked (): boolean {
    return !!this.dropletsPagerDutyAlerts.find((alert) =>  alert.dropletID == this.selectedDroplet.dropletID && alert.shouldSend);
  }

  updateLastSyncronized() {
    this.lastSyncronized = new Date();
    this.dataService.lastSync.next(this.lastSyncronized);
    if (this.barTimeout) clearInterval(this.barTimeout);
    this.barWidth = 0;
    let timeElapsed: number = 0;
    this.barTimeout = setInterval(() => {
      timeElapsed += 10;
      this.barWidth = timeElapsed / 100;
      if (this.barWidth > 100) {
        this.barWidth = 100;
      }
    }, 10);
  }

  openSettingsModal (droplet: SpareDroplet, digitalOceanSettings: boolean) {
    droplet.digitalOceanSettings = digitalOceanSettings;
    this.selectedDroplet = droplet;
  }

  modalClosed (droplet) {
    if (droplet) {
      this.getAllPagerDutyAlerts();
      this.getAllEmailAlerts();
      this.socketService.socket.emit('getAllDropletsAndLoadBalancers');
    }
    this.selectedDroplet = null;
  }
}
