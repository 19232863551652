<div class="loginContainer">
  <div class="card" *ngIf="!loading">
    <img src="../../../assets/logoSmall.svg" alt="">
    <h1>Spare Dashboard</h1>
    <input type="email" placeholder="E-mail" [(ngModel)]="email" (keydown)="keyDownFunction($event)">
    <input type="password" placeholder="Password" [(ngModel)]="password" (keydown)="keyDownFunction($event)">
    <button [ngClass]="{disabled: cannotSubmit()}" (click)="login()">login</button>
  </div>
  <div class="lds-roller" *ngIf="loading"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>
