import {Component, Input, OnInit} from '@angular/core';
import {LoadBalancer} from '../../models/load-balancer.model';
import {HealthCheckSettings} from '../../models/HealthcheckSettings.model';

@Component({
  selector: 'spare-droplets-card-header',
  templateUrl: './droplets-card-header.component.html',
  styleUrls: ['./droplets-card-header.component.scss']
})
export class DropletsCardHeaderComponent implements OnInit {

  @Input() balancer: LoadBalancer;
  @Input() healthChecks;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * this function returns the image pass that describes the state of connection load balancer
   * by calculate the number of health checks data per droplet in passed load balancer
   * @param balancer {LoadBalancer} - the load balancer need to  get the image for it
   * @returns {string} - the img pass
   */
  getOnlineImage(balancer: LoadBalancer) {
    if (this.healthChecks) {
      let numberHealthy: number = 0;
      for (let droplet of balancer.droplets) {
        if (droplet.healthCheckSettings) {
          if (6 - +this.healthChecks[droplet.dropletID].healthyCount < 3 && this.healthChecks[droplet.dropletID].healthyCount > 4) {
            numberHealthy += 1;
          }
        }
      }
      if (numberHealthy === balancer.droplets.length) {
        return '../../../assets/online_good.svg';
      }
      if (numberHealthy != 0) {
        return '../../../assets/online_semi.svg';
      }
      return '../../../assets/online_red.svg';
    }
    else {
      return '../../../assets/online_red.svg';
    }
  }

  /**
   * this function returns the number of droplets in the passed load balancer based of the
   * @param balancer {LoadBalancer} - the load balancer need to  get the health count for it
   * @returns {number} - health count
   */
  getHealthyCount(balancer: LoadBalancer) {
    if (this.healthChecks) {
      let numberHealthy: number = 0;
      for (let droplet of balancer.droplets) {
        if (6 - +this.healthChecks[droplet.dropletID].healthyCount < 3 && this.healthChecks[droplet.dropletID].healthyCount > 4) {
          numberHealthy += 1;
        }
      }
      return numberHealthy;
    }
    return 0;
  }
}
