import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropdownOption} from '../../models/dropdown.option.model';
import {SpareDroplet} from '../../models/droplet.model.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {HealthCheckSettings} from '../../models/HealthcheckSettings.model';
import {NetworksV4} from '../../models/networksV4.model';
import {ErrorHandlerService} from '../../error-handler.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DropletsAlertsService} from '../../services/dropletAlerts/droplets-alerts.service';

@Component({
  selector: 'spare-droplet-settings-modal',
  templateUrl: './droplet-settings-modal.component.html',
  styleUrls: ['./droplet-settings-modal.component.scss']
})
export class DropletSettingsModalComponent implements OnInit {
  @Input() droplet: SpareDroplet;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  @Input() emailAlertChecked: boolean;
  @Input() pagerDutyAlertChecked: boolean;

   _protocols: DropdownOption[] = [{name: 'HTTP', value:'http'}, {name:'HTTPS', value: 'https'}];
   _settingForm: FormGroup;
   _isLoading: boolean = false;
   _shouldViewForm: boolean = false;
   _shouldSubmitEmailAlert: boolean = false;
   _shouldSubmitPDAlert: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dataService: DataService,
              private errorHandler: ErrorHandlerService,
              private snackBar: MatSnackBar,
              private dropletsAlertsService: DropletsAlertsService) { }

  ngOnInit() {
    this.initForm();
    this._shouldViewForm = !!this.droplet.healthCheckSettings;
  }

  /**
   * Initialize setting form  group using angular form builder.
   */
  initForm () {
    const settings: HealthCheckSettings = this.droplet.healthCheckSettings;
    if (settings) {
      this._settingForm = this.formBuilder.group({
        protocol: [settings.protocol? settings.protocol: '', Validators.required],
        port: [settings.port? settings.port: '', Validators.required, ],
        path: [settings.path? settings.path: '', Validators.required],
        host: [settings.overRideHost? settings.overRideHost: this.droplet.networks.v4[0].ip_address],
        checkInterval: [settings.check_interval_seconds? settings.check_interval_seconds: '', Validators.required],
        responseTimeout: [settings.response_timeout_seconds? settings.response_timeout_seconds: '', Validators.required],
        unhealthyThreshold: [settings.unhealthy_threshold? settings.unhealthy_threshold: '', Validators.required],
        healthyThreshold: [settings.healthy_threshold? settings.healthy_threshold: '', Validators.required],
        emailAlert: [this.emailAlertChecked],
        PDAlert: [this.pagerDutyAlertChecked],
      })
    } else {
      this._settingForm = this.formBuilder.group({
        protocol: ['', Validators.required],
        port: ['', Validators.required],
        path: ['', Validators.required],
        host: [this.droplet.networks.v4[0].ip_address],
        checkInterval: ['', Validators.required],
        responseTimeout: ['', Validators.required],
        unhealthyThreshold: ['', Validators.required],
        healthyThreshold: ['', Validators.required],
        emailAlert: [this.emailAlertChecked],
        PDAlert: [this.pagerDutyAlertChecked],
      })
    }
    if (this.droplet.digitalOceanSettings) {
      this._settingForm.disable();
      this._settingForm.controls.emailAlert.enable();
      this._settingForm.controls.PDAlert.enable();
    }
  }

  /**
   *  parsing settings from the value of the setting form and submit it to the server.
   *  submit the new alerts value if needed for email and pager duty.
   */
  submitSettings () {
    if (this._settingForm.valid) {
      const controls = this._settingForm.controls;
      const settings: HealthCheckSettings = {
        healthy_threshold: controls.healthyThreshold.value,
        unhealthy_threshold: controls.unhealthyThreshold.value,
        response_timeout_seconds: controls.responseTimeout.value,
        check_interval_seconds:controls.checkInterval.value,
        path: controls.path.value,
        port: controls.port.value,
        protocol: controls.protocol.value,
        dropletID: this.droplet.dropletID,
        overRideHost: controls.host.value,
      };
      this._isLoading = true;
      if (this._shouldSubmitEmailAlert) {
        this.changeEmailAlert(this.droplet.dropletID, this._settingForm.value.emailAlert);
      }
      if (this._shouldSubmitPDAlert) {
        this.changePagerDutyAlert(this.droplet.dropletID, this._settingForm.value.PDAlert);
      }
      this.dataService.saveDropletSettings(settings).subscribe((droplet) => {
        this._isLoading = false;
        this.snackBar.open('Internal health check settings saved successfully', undefined,
          {
            duration: 3000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: "toastSuccess",
          });
        this.modalClosed.emit(droplet);
      }, error => {
        this._isLoading = false;
        this.errorHandler.handleError(error);
      });
    } else {
      this.snackBar.open('All fields are required ', undefined,
        {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "toast",

        })
    }
  }

  /**
   * adding or remove mail alert
   * @param {string} dropletID : the ID of the droplet that you want to change email alert status for.
   * @param {boolean} checked: the status of the alert want to change to.
   */
  changeEmailAlert (dropletID: string, checked: boolean) {
    if (checked) {
      this.addingDropletEmailAlert(dropletID);
    } else {
      this.deleteEmailAlert(dropletID);
    }
  }
  /**
   * adding or remove pager duty alert
   * @param {string} dropletID : the ID of the droplet that you want to change email alert status for.
   * @param {boolean} checked: the status of the alert want to change to.
   */
  changePagerDutyAlert (dropletID, checked) {
    if (checked) {
      this.addingDropletPagerDutyAlert(dropletID);
    } else {
      this.deletePagerDutyAlert(dropletID);
    }
  }

  /**
   * Deleting email alert form passed droplet ID
   * @param {string} dropletID: the ID of the droplet that you want to change email alert status for.
   */
  deleteEmailAlert (dropletID: string) {
    this.dropletsAlertsService.deleteDropletEmailAlert(dropletID).subscribe(res => {
    }, err => {
      this.errorHandler.handleError(err);
    });
  }

  /**
   * Deleting pager duty alert form passed droplet ID
   * @param {string} dropletID: the ID of the droplet that you want to change pager duty alert status for.
   */
  deletePagerDutyAlert (dropletID) {
    this.dropletsAlertsService.deleteDropletPagerDutyAlert(dropletID).subscribe(res => {
    }, err => {
      this.errorHandler.handleError(err);
    });
  }

  /**
   * Adding email alert form passed droplet ID
   * @param {string} dropletID: the ID of the droplet that you want to change email alert status for.
   */
  addingDropletEmailAlert (dropletID) {
    this.dropletsAlertsService.addDropletEmailAlert(dropletID).subscribe(res => {

    }, err => {
      this.errorHandler.handleError(err);
    });
  }

  /**
   * Adding pager duty alert form passed droplet ID
   * @param {string} dropletID: the ID of the droplet that you want to change pager duty alert status for.
   */
  addingDropletPagerDutyAlert (dropletID) {
    this.dropletsAlertsService.addDropletPagerDutyAlert(dropletID).subscribe(res => {

    }, err => {
      this.errorHandler.handleError(err);
    });
  }

}
